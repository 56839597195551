

const DATASET_COA = {
    "nsfw": false,
    "captions_file": "1024x1024/sections-etc.txt.py",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "a stone stove, 32x32 pixel art, top-down 2.5D, tileset object, gray background",
    "negative_prompt": "blur, unclear",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
}

const DATASET_COA_FRAMES = {
    "nsfw": false,
    "captions_file": "1024x1024/coa-assets/coa-frames.txt",
    "caption_prefix": "",  // fantasy RPG pixel art:
    "caption_suffix": "",  // "", (on a white background)
    "validation_prompt": "a big background featuring a Christmas town",  // fantasy RPG pixel art:
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "1024x1024/coa-assets",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
}

const DATASET_COA_ITEMS = {
    "nsfw": false,
    "captions_file": "1024x1024/coa-items-sorted.txt",
    "caption_prefix": "",  // fantasy RPG pixel art:
    "caption_suffix": "",  // "", (on a white background)
    "validation_prompt": "a crown of ice.|a powerful nature-themed hatchet.",  // fantasy RPG pixel art:
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
}

const DATASET_COA_ITEMS_PREFIXED = {
    "nsfw": false,
    "captions_file": "1024x1024/coa-items-sorted.txt",
    "caption_prefix": "A pixel art sprite of ",  // fantasy RPG pixel art:
    "caption_suffix": "",  //  (with a grey background)
    "validation_prompt": "A pixel art sprite of a crown of ice.|A pixel art sprite of a powerful nature-infused hatchet.",  // fantasy RPG pixel art:
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
}

const DATASET_COA_BOSSES_AGAIN_SOUTH = {
    "nsfw": false,
    "captions_file": "1024x1024/coa-bosses-again-south.txt",
    "caption_prefix": "fantasy RPG pixel art: ",  // fantasy RPG pixel art:
    "caption_suffix": " (game character)",  // , pixel art boss monster character sprite, Curse of Aros, fantasy 2D MMORPG, gray background
    "validation_prompt": "a blacksmith in apron, holding a hammer|fantasy RPG pixel art: a blacksmith in apron, holding a hammer (game character)",  // fantasy RPG pixel art:
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
}

const DATASET_COA_BOSSES_AGAIN_SOUTH_GPT = {
    "nsfw": false,
    "captions_file": "1024x1024/coa-bosses-again-south-gpt4.txt",
    "caption_prefix": "pixel art: ",  // fantasy RPG pixel art:
    "caption_suffix": " (with a grey background)",  // , pixel art boss monster character sprite, Curse of Aros, fantasy 2D MMORPG, gray background
    "validation_prompt": "pixel art: A sprite asset showing a friendly blacksmith facing south.|pixel art: a game level with a beautiful lush forest and a bandit encampment",  // fantasy RPG pixel art:
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
}

const DATASET_COA_BOSSES_AGAIN_SOUTH_AND_OBJECTS = {
    "nsfw": false,
    "captions_file": "1024x1024/coa-bosses-and-objects.txt",
    "caption_prefix": "pixel art: ",  // fantasy RPG pixel art:
    "caption_suffix": " (with a grey background)",  // , pixel art boss monster character sprite, Curse of Aros, fantasy 2D MMORPG, gray background
    "validation_prompt": "pixel art: A sprite asset showing a friendly blacksmith facing south.|pixel art: a game level with a beautiful lush forest and a bandit encampment",  // fantasy RPG pixel art:
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
}

const DATASET_COA_MAPS_128 = {
    "nsfw": false,
    "captions_file": "1024x1024/maps-128.txt",
    "caption_prefix": "",  // "top-down Stardew Valley pixel art game scene: ",  // "a vibrant pixel art top-down 2.5D game level showing ",  // fantasy RPG pixel art:
    "caption_suffix": "",  // , pixel art boss monster character sprite, Curse of Aros, fantasy 2D MMORPG, gray background
    "validation_prompt": "a jungle farm",  // a vibrant pixel art top-down 2.5D game level showing  // fantasy RPG pixel art:
    "negative_prompt": "bland, blurry",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 25,
}

const DATASET_COA_MAP = {
    "nsfw": false,
    "captions_file": "1024x1024/maps-256-captions-gpt4-cropped.txt",  // "1024x1024/coa-map-mix.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "a jungle farm",  // fantasy RPG pixel art:
    "negative_prompt": "bland, blurry",
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 25,
}

// A top-down rpg pixel art game tilemap depicting a mushroom biome.
const DATASET_COA_MAPS_128_BASE = {
    "nsfw": false,
    "captions_file": "1024x1024/maps-128.txt",
    "caption_prefix": "A top-down rpg pixel art game tilemap featuring ",  // A top-down rpg pixel art game tilemap featuring
    "caption_suffix": "",
    "validation_prompt": "A top-down rpg pixel art game tilemap featuring an alchemist's little hideout hut.",  // "A top-down rpg pixel art game tilemap featuring an ancient underground city, set in a dark, foreboding biome. The scene features sprawling ruins made of dark slate-blue obsidian stones, illuminated sparsely by bioluminescent fungi casting an eerie glow. Intricate carvings and mysterious artifacts are scattered among the ruins, adding a sense of age-old secrets and danger lurking in the shadows.",  // fantasy RPG pixel art:
    "negative_prompt": "unclear, blurry",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
    // "compute_mean_std": true,
}

const DATASET_COA_BOSSES_AGAIN_NESW = {
    "nsfw": false,
    "captions_file": "1024x1024/coa-bosses-again-nesw.txt",
    "caption_prefix": "",  // fantasy RPG pixel art:
    "caption_suffix": "",
    "validation_prompt": "a blacksmith in apron, holding a hammer",  // fantasy RPG pixel art:
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
}

const DATASET_COA_BOSSES_AGAIN_SHEETS = {
    "nsfw": false,
    "captions_file": "1024x1024/coa-bosses-again-sheets.txt",
    "caption_prefix": "",  // fantasy RPG pixel art:
    "caption_suffix": "",
    "validation_prompt": "a blacksmith in apron, holding a hammer",  // fantasy RPG pixel art:
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
}

const DATASET_COA_TILES = {
    "nsfw": false,
    // "captions_file": "1024x1024/coa-tiles-3-namesonly.txt",
    "autocaption": true,
    "caption_prefix": "",  // fantasy RPG pixel art sprite:   // "a vibrant pixel art top-down RPG Maker tileset sprite of ",  // fantasy RPG pixel art:
    "caption_suffix": "",  //  on a grey background
    "validation_prompt": "a dwarven goldsmith anvil|a table in the style of nature",  // fantasy RPG pixel art:
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "1024x1024/poepvlekje/scaled-with-flip",
    "dropout_caption": "",
    "denoiser_steps": 20,
}

//

const DATASET_OSRS_DRAGON = {
    "nsfw": false,
    "captions_file": "1024x1024/osrs-dragon.txt",
    "caption_prefix": "",  // fantasy RPG pixel art:
    "caption_suffix": ", low poly 3d item model, Old-School RuneScape, fantasy MMORPG",
    "validation_prompt": "Dharoks scimitar, low poly 3d item model, Old-School RuneScape, fantasy MMORPG",  // fantasy RPG pixel art:
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
}

const DATASET_OSRS_MONSTERS = {
    "nsfw": false,
    "captions_file": "osrs-monsters-2nft.txt",
    "caption_prefix": "",  // fantasy RPG pixel art:
    "caption_suffix": "",
    "validation_prompt": "a low poly model of a serpentine dragon.",  // fantasy RPG pixel art:
    "negative_prompt": "ugly",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "use_compel": true,
    "denoiser_steps": 20,
}

const DATASET_COA_MONSTERS = {
    "nsfw": false,
    "captions_file": "1024x1024/coa-bosses-whee.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "a deep-purple giant orc",  // fantasy RPG pixel art:
    "negative_prompt": "",
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
}

const DATASET_COA_COSMETICS = {
    "nsfw": false,
    "captions_file": "1024x1024/coa-assets/cosmetics.txt.py",
    "caption_prefix": "a character ",  // fantasy RPG pixel art:
    "caption_suffix": "",
    "validation_prompt": "a character wearing a hamster mask",  // fantasy RPG pixel art:
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
}

const DATASET_OSRS_BOSSES = {
    "nsfw": false,
    "captions_file": "./osrs-bosses.txt.py",
    "caption_prefix": "low-poly 3d model of ",  // fantasy RPG pixel art:
    "caption_suffix": ", Old-School RuneScape (OSRS) boss monster",
    "validation_prompt": "low-poly 3d model of an undead skeletal necromancer, Old-School RuneScape (OSRS) boss monster",  // fantasy RPG pixel art:
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 25,
}

const DATASET_OSRS_BOSSES_UNPREFIXED = {
    "nsfw": false,
    "captions_file": "./osrs-bosses.txt.py",
    "caption_prefix": "",  // fantasy RPG pixel art:
    "caption_suffix": "",
    "validation_prompt": "an undead skeletal necromancer",  // fantasy RPG pixel art:
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 25,
}

const DATASET_NUDES = {
    "nsfw": true,
    "captions_file": "nudes2-resampled-gpt4.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A naked woman with blonde hair is sitting in a white armchair by a pool, spreading her legs invitingly",
    "negative_prompt": "ugly",
    // "p_caption_dropout": 1.0,
    "instance_data_dir": "",
    "autocaption": false,
    "dropout_caption": "a beautiful naked woman",
    "inference_width": 832,
    "inference_height": 1216,
    "denoiser_steps": 50,
}

const DATASET_ALYSSA = {
    "nsfw": true,
    "captions_file": "alyssa.txt",
    "caption_prefix": "a beautiful naked woman ",
    "caption_suffix": "",
    "validation_prompt": "a beautiful naked woman bending over forward, showing her ass and vagina",
    "negative_prompt": "",
    // "p_caption_dropout": 1.0,
    "instance_data_dir": "alyssa",
    "autocaption": false,
    "dropout_caption": "a beautiful naked woman",
    "inference_width": 832,
    "inference_height": 1216,
    "denoiser_steps": 30,
}

const DATASET_ALYSSA_WIDE = {
    "nsfw": true,
    "captions_file": "combined-alyssa-wide.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "a beautiful woman bending over forward, showing her ass and vagina while grabbing her ass",
    "negative_prompt": "",
    // "p_caption_dropout": 1.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "inference_width": 832,
    "inference_height": 1216,
    "denoiser_steps": 30,
}

const DATASET_ALYSSA_WIDE_NOSAMPLE = {
    "nsfw": true,
    "captions_file": "combined-alyssa-wide-no-resample.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "a beautiful woman bending over forward, showing her ass and vagina while grabbing her ass",
    "negative_prompt": "",
    // "p_caption_dropout": 1.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "inference_width": 832,
    "inference_height": 1216,
    "denoiser_steps": 30,
}

const DATASET_GPT4_NUDES = {
    "nsfw": true,
    "captions_file": "nudes2-resampled-gpt4.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "a beautiful naked woman laying in bed with her legs spread wide, revealing her genital area to the viewer in explicit detail.",
    "negative_prompt": "",
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "inference_width": 832,
    "inference_height": 1216,
    "denoiser_steps": 30,
    "compute_mean_std": true,
}

const DATASET_GPT4_NUDES_PORTRAIT = {
    "nsfw": true,
    "captions_file": "nudes2-portrait-autocaption.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "a beautiful naked woman laying in bed with her legs spread wide.",
    "negative_prompt": "",
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "inference_width": 832,
    "inference_height": 1216,
    "denoiser_steps": 30,
    "use_compel": true,
    // $"compute_mean_std": true,
}

const DATASET_NIKKI = {
    "nsfw": true,
    "captions_file": "nikki.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A stunning freshly-graduated woman with long ash-gray hair is seated outdoors, with her bright blue top pulled down to expose her big, perky breasts and hard nipples. She has a mischievous smile on her face, knowing she's giving a naughty view of her bare breasts. She sits confidently, exuding sexual allure as she invites you to admire her gorgeous, exposed breasts and wet pussy.",
    "negative_prompt": "",
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "inference_width": 832,
    "inference_height": 1216,
    "denoiser_steps": 30,
    "use_compel": true,
    // $"compute_mean_std": true,
}

const DATASET_EVA = {
    "nsfw": true,
    "captions_file": "eva-berger-edited.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A beautiful naked blonde woman laying in bed with her legs spread wide.",
    "negative_prompt": "",
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "inference_width": 832,
    "inference_height": 1216,
    "denoiser_steps": 30,
    "use_compel": true,
    // $"compute_mean_std": true,
}

const DATASET_MB = {
    "nsfw": true,
    "captions_file": "maps-bitches.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A pixel art image showing a young woman posing naked on a bed.",
    "negative_prompt": "",
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "inference_width": 1024,
    "inference_height": 1024,
    "denoiser_steps": 25,
    "use_compel": true,
    // $"compute_mean_std": true,
}

const DATASET_OSRS_COA_MAPS = {
    "captions_file": "1024x1024/osrs-and-coa-maps.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "2D pixel art game scene: a tranquil and beautiful forest.|A 3D rendering of a tranquil and beautiful forest.",
    "negative_prompt": "",
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "inference_width": 1024,
    "inference_height": 1024,
    "denoiser_steps": 25,
    "use_compel": true,
}

const DATASET_OSRS_COA_MAPS2 = {
    "captions_file": "1024x1024/osrs-and-coa-maps-gpt.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "2D pixel art game scene: a tranquil and beautiful forest.|3D stylized game scene: a tranquil and beautiful forest.",
    "negative_prompt": "",
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "inference_width": 1024,
    "inference_height": 1024,
    "denoiser_steps": 25,
    "use_compel": true,
}

const DATASET_KALI = {
    "nsfw": true,
    "captions_file": "kali-roses/captions.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    // "validation_prompt": "an explicit photo of a woman named Kali Roses bending over in a sexually explicit way, with her butt to the camera, fully-naked, with a clear view of her vagina from behind",
    "validation_prompt": "an explicit photo of a woman named Kali Roses bending over forward, leaning on a brown couch as she pulls her panties down while looking over her shoulder.",
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "kali-roses",
    "autocaption": false,
    "dropout_caption": "",
    "inference_width": 832,
    "inference_height": 1216,
    "denoiser_steps": 30,
}

// https://public.dreamshaper.io/sdxl/webinf/oo_1712837641358618225_0.png


const DATASET_DOODLE = {
    "nsfw": false,
    "captions_file": "1024x1024/doodles.txt",
    "caption_prefix": "a stick-figure doodle of ",
    "caption_suffix": " (monochrome, white background)",
    "validation_prompt": "a stick-figure doodle of a boy on one knee proposing to a girl with fuzzy hair, smiling (monochrome, white background)",
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
}

const DATASET_PAARD = {
    "nsfw": false,
    "captions_file": null,
    "caption_prefix": "game illustration of ",
    "caption_suffix": "",
    "validation_prompt": "game illustration of a king in royal attire, standing, with a crown on his head, wearing a cape and holding a scepter",
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "kerkhofs",
    "autocaption": true,
    "dropout_caption": "",
    "denoiser_steps": 25,
}

const DATASET_PAARD_BOSJE = {
    "nsfw": false,
    "captions_file": "paardje/foliage-captions-gpt4.txt",
    "caption_prefix": "game illustration of ",
    "caption_suffix": " (grey background)",
    "validation_prompt": "game illustration of a birch tree in autumn colors",
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "paardje",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 25,
}

const DATASET_PAARDENVLIEG = {
    "nsfw": false,
    "captions_file": null,
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "a venomous black snake, coiled, sitting idle",
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "miertjes",
    "autocaption": true,
    "dropout_caption": "",
    "denoiser_steps": 25,
}

const DATASET_PAARDJE_UI = {
    "nsfw": false,
    "captions_file": "paardje/captions5.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "Stylized icon of Mars featuring red and orange hues, with cratered surfaces and polar ice caps on a gray background.|Cartoon icon of a grinning jack-o'-lantern with a witch's hat.",
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "paardje",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 25,
}

const DATASET_BROBERTJE = {
    "nsfw": false,
    "captions_file": "paardje/brobertje.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A stylized snake with a black leathery skin.",
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "paardje",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 25,
}

const DATASET_BROBERTJE2 = {
    "nsfw": false,
    "captions_file": "paardje/svg-poepen.txt",
    "caption_prefix": "Game illustration of ",
    "caption_suffix": "",
    "validation_prompt": "a game illustration of a sacred Roman trophy.",
    "negative_prompt": "ugly",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "a game illustration",
    "denoiser_steps": 25,
}

const DATASET_BROBERTJE3 = {
    "nsfw": false,
    "captions_file": "paardje/svg-poepen-met-stukjes.txt",
    "caption_prefix": "Game illustration: ",
    "caption_suffix": "",
    "validation_prompt": "Game illustration: an ice cube.",
    "negative_prompt": "ugly",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",  // "Game illustration",
    "denoiser_steps": 25,
}

const DATASET_BACKGROUND = {
    "nsfw": false,
    "captions_file": "1024x1024/coa-backgrounds.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "Pixel art image of a green chest on a raised platform, hidden inside a jungle bamboo hut.",
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 25,
    "inference_width": 1344,
    "inference_height": 768,
}

const DATASET_OSRS_BACKGROUND = {
    "nsfw": false,
    "captions_file": "1024x1024/osrs-backgrounds.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "Old School RuneScape's welcome screen with a barren desert featuring skeletal elements in the background, with sandstone-colored hues and the title in sandstone-like texture.",
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 25,
    "inference_width": 1216,
    "inference_height": 832,
}

const DATASET_OSRS_UI = {
    "nsfw": false,
    "captions_file": "1024x1024/osrs-ui-1024.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A game interface from Old School RuneScape showing a mysterious map, revealing a hidden treasure chest in the wilderness.",
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 30,
    "inference_width": 1024,
    "inference_height": 1024,
}

const DATASET_OSRS_MAPS = {
    "nsfw": false,
    "captions_file": "1024x1024/osrs-scenes-filtered.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A harrowing and frosty environment with a snow-laden ground, a frozen river, and remnants of desolate wooden structures. Skeletal remains and a damaged boat are visible near the riverbank.",
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 30,
    "inference_width": 1216,
    "inference_height": 832,
}

const DATASET_LESBIANS = {
    "nsfw": true,
    "captions_file": "lesbians.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "Two women are engaged in a 69 position on a bed. The woman on top, with dark hair and visible tan lines, is bent over and licking the vulva of the woman beneath her.",
    "negative_prompt": "ugly, blurry, low quality",
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "inference_width": 832,
    "inference_height": 1216,
    "denoiser_steps": 30,
    "use_compel": true,
    // $"compute_mean_std": true,
}

const DATASET_LESBIANS_V2 = {
    "nsfw": true,
    "captions_file": "lesbians-all-out-captions.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A photo showing a woman kneeling on a table, surrounded by red balloons and a cake. She is fully naked. She has dark hair, black stockings, and is wearing high heels.|A photo showing two women engaged in a 69 position on a bed. The woman on top, with dark hair and visible tan lines, is bent over and licking the vulva of the woman beneath her.",
    "negative_prompt": "ugly, blurry, low quality",
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "inference_width": 832,
    "inference_height": 1216,
    "denoiser_steps": 30,
    "use_compel": true,
    // $"compute_mean_std": true,
}

const DATASET_OSRS_ICONS = {
    "nsfw": false,
    "captions_file": "osrs-icons.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "An illustration of a central black crescent moon encircled by a segmented grey ring with a flame depicted in blue.",
    "negative_prompt": "",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
    "inference_width": 1024,
    "inference_height": 1024,
}

// rs3/concept-art-crop-batch_output.jsonl.txt
const DATASET_RS3_CONCEPT_ART = {
    "nsfw": false,
    "captions_file": "rs3/concept-art-crop-batch_output.jsonl.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A concept art rendering featuring the Arch-Glacor from RuneScape, depicted with Vorkath on his side.",
    "negative_prompt": "ugly, blurry, low quality",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
    "inference_width": 1344,
    "inference_height": 768,
}

const DATASET_RS3_OBJECTS_HD = {
    "nsfw": false,
    "captions_file": "rs3-objects-hd.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A high-quality 3D model of a statue of a gargoyle, carved from sandstone.",
    "negative_prompt": "ugly, blurry, low quality",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
    "inference_width": 832,
    "inference_height": 1152,
}

const DATASET_RS3_OBJECTS_LD = {
    "nsfw": false,
    "captions_file": "rs3-objects-ld.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A low-poly 3D model of a statue of a gargoyle, carved from sandstone.",
    "negative_prompt": "ugly, blurry",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
    "inference_width": 832,
    "inference_height": 1152,
}

const DATASET_RS3_COA_OBJECTS = {
    "nsfw": false,
    "captions_file": "1024x1024/rs3-and-coa-objects.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A pixel art 2D sprite of a broken wooden signpost with three planks, the central one marked by a red painted symbol. The planks are weathered and unevenly broken.|A stylized 3D model of a broken wooden signpost with three planks, the central one marked by a red painted symbol. The planks are weathered and unevenly broken.",
    "negative_prompt": "ugly, bland, blurry",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
    "inference_width": 1024,
    "inference_height": 1024,
}

const DATASET_SOLO = {
    "nsfw": true,
    "captions_file": "solo.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A close-up image of a woman spreading her labia to expose her vagina. She is wearing black lingerie, which is partially visible, and her fingers are parting her vagina lips.",
    "negative_prompt": "ugly, blurry",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
    "inference_width": 832,
    "inference_height": 1152,
}

const DATASET_JONETAN = {
    "nsfw": false,
    "captions_file": "1024x1024/sunhaven-prep-batch_output.jsonl.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A pixel art sprite of a sofa.",
    "negative_prompt": "blurry",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
    "inference_width": 1024,
    "inference_height": 1024,
}

const DATASET_COA_MONSTERS_NEW = {
    "nsfw": false,
    "captions_file": "1024x1024/coa-bosses-single.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A pixel art sprite of a dark spider on a gray background",
    "negative_prompt": "blurry",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
    "inference_width": 1024,
    "inference_height": 1024,
}


const DATASET_RS3_MONSTERS_NEW = {
    "nsfw": false,
    "captions_file": "rs3-npc-subset-batch_output.jsonl.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A 3D model of a dark spider on a gray background",
    "negative_prompt": "blurry",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
    "inference_width": 1024,
    "inference_height": 1024,
}

const DATASET_RS3_COA_MONSTERS_NEW = {
    "nsfw": false,
    "captions_file": "1024x1024/coa-bosses-single-and-rs3.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A pixel art sprite of a ghostly figure with an eerie, pale blue appearance, tattered clothing, and claw-like fingers, floating against a grey background.|A 3D model of a ghostly figure with an eerie, pale blue appearance, tattered clothing, and claw-like fingers, floating against a grey background.",
    "negative_prompt": "blurry",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": "1024x1024",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
    "inference_width": 1024,
    "inference_height": 1024,
}

const DATASET_COA_RS3_ITEMS = {
    "nsfw": false,
    "captions_file": "rs3-coa-items.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "2D pixel art game asset: a dark, spiked, angular helm known as the Torva full helm.|2D pixel art game asset: Dharok's platebody, a dark and rugged chest piece with shoulder guards featuring bone-like protrusions and metallic textures.",
    "negative_prompt": "ugly, blurry",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
    "inference_width": 1024,
    "inference_height": 1024,
}

const DATASET_THICK = {
    "nsfw": true,
    "captions_file": "thick.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A nude woman with blonde hair stands indoors, leaning against a countertop. She displays her breasts characterized by small, erect nipples and a completely shaved pussy. She wears black high heels while standing on a tiled floor.",
    "negative_prompt": "ugly, blurry",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
    "inference_width": 768,
    "inference_height": 1280,
}

const DATASET_THICK_MMM = {
    "nsfw": true,
    "captions_file": "thick-mmm.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A nude woman with blonde hair stands indoors, leaning against a countertop. She displays her breasts characterized by small, erect nipples and a completely shaved pussy. She wears black high heels while standing on a tiled floor.",
    "negative_prompt": "ugly, blurry",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 20,
    "inference_width": 768,
    "inference_height": 1280,
}

const DATASET_CRAFTPIX_ITEMS = {
    "nsfw": false,
    "captions_file": "craftpix-icons-32x32.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A pixel art icon of a lava dragon egg.",
    "negative_prompt": "ugly, blurry",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 25,
    "inference_width": 1024,
    "inference_height": 1024,
}

const DATASET_CRAFTPIX_HD_ICONS = {
    "nsfw": false,
    "captions_file": "craftpix-hd-icons.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A game illustration of a cheese wheel.",
    "negative_prompt": "ugly, blurry",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 25,
    "inference_width": 1024,
    "inference_height": 1024,
}

const DATASET_RAVLIK_MONSTERS = {
    "nsfw": false,
    "captions_file": "ravlik-monsters.txt",
    "caption_prefix": "",
    "caption_suffix": "",
    "validation_prompt": "A character illustration of a wraith dragon.",
    "negative_prompt": "ugly, blurry",
    // "p_caption_dropout": 0.0,
    "instance_data_dir": ".",
    "autocaption": false,
    "dropout_caption": "",
    "denoiser_steps": 25,
    "inference_width": 1024,
    "inference_height": 1024,
}

//rs3-npc-subset-batch_output.jsonl.txt
//1024x1024/coa-bosses-single-and-rs3.txt


export const NAMED_DATASETS = {
    'ravlik-monsters': DATASET_RAVLIK_MONSTERS,
    'craftpix-hd-icons': DATASET_CRAFTPIX_HD_ICONS,
    'craftpix-items': DATASET_CRAFTPIX_ITEMS,
    'items-rs3-coa': DATASET_COA_RS3_ITEMS,
    'coa-monsters-new': DATASET_COA_MONSTERS_NEW,
    'rs3-monsters-new': DATASET_RS3_MONSTERS_NEW,
    'rs3-coa-monsters': DATASET_RS3_COA_MONSTERS_NEW,
    'sunhaven': DATASET_JONETAN,
    'rs3-and-coa-tiles': DATASET_RS3_COA_OBJECTS,
    'rs3-concepts': DATASET_RS3_CONCEPT_ART,
    'solo': DATASET_SOLO,
    'rs3-objects-hd': DATASET_RS3_OBJECTS_HD,
    'rs3-objects-ld': DATASET_RS3_OBJECTS_LD,
    'osrs-icons': DATASET_OSRS_ICONS,
    "lesbians": DATASET_LESBIANS,
    "lesbians-v2": DATASET_LESBIANS_V2,
    "osrs-coa-maps": DATASET_OSRS_COA_MAPS,
    "osrs-coa-maps-2": DATASET_OSRS_COA_MAPS2,
    "maps-bitches": DATASET_MB,
    "nikki": DATASET_NIKKI,
    "eva": DATASET_EVA,
    "thick": DATASET_THICK,
    "thick-mmm": DATASET_THICK_MMM,
    "coa": DATASET_COA,
    "coa-frames": DATASET_COA_FRAMES,
    "coa-items": DATASET_COA_ITEMS,
    "coa-items-prefixed": DATASET_COA_ITEMS_PREFIXED,
    "coa-bosses-again-south": DATASET_COA_BOSSES_AGAIN_SOUTH,
    "coa-bosses-again-south-gpt": DATASET_COA_BOSSES_AGAIN_SOUTH_GPT,
    "coa-bosses-again-south-and-objects": DATASET_COA_BOSSES_AGAIN_SOUTH_AND_OBJECTS,
    "coa-bosses-again-nesw": DATASET_COA_BOSSES_AGAIN_NESW,
    "coa-bosses-again-sheets": DATASET_COA_BOSSES_AGAIN_SHEETS,
    "coa-maps-128": DATASET_COA_MAPS_128,
    "coa-map": DATASET_COA_MAP,
    "coa-monsters": DATASET_COA_MONSTERS,
    "coa-cosmetics": DATASET_COA_COSMETICS,
    "osrs-dragon": DATASET_OSRS_DRAGON,
    "osrs-monsters": DATASET_OSRS_MONSTERS,
    "osrs-bosses": DATASET_OSRS_BOSSES,
    "osrs-bosses-unprefixed": DATASET_OSRS_BOSSES_UNPREFIXED,
    "nudes": DATASET_NUDES,
    "alyssa": DATASET_ALYSSA,
    "alyssa-wide": DATASET_ALYSSA_WIDE,
    "alyssa-wide-no-resample": DATASET_ALYSSA_WIDE_NOSAMPLE,
    "gpt4-nudes": DATASET_GPT4_NUDES,
    "gpt4-nudes-portrait": DATASET_GPT4_NUDES_PORTRAIT,
    "kali": DATASET_KALI,
    "doodle": DATASET_DOODLE,
    "paard": DATASET_PAARD,
    "paard-bosje": DATASET_PAARD_BOSJE,
    "paardenvlieg": DATASET_PAARDENVLIEG,
    "paardje-ui": DATASET_PAARDJE_UI,
    "brobertje": DATASET_BROBERTJE,
    "brobertje2": DATASET_BROBERTJE2,
    "brobertje3": DATASET_BROBERTJE3,
    "background": DATASET_BACKGROUND,
    "osrs-background": DATASET_OSRS_BACKGROUND,
    "osrs-ui": DATASET_OSRS_UI,
    "osrs-maps": DATASET_OSRS_MAPS,
};

export const DATASETS = Object.values(NAMED_DATASETS);




export const HIDDEN = [
    'abort',
    'allow_tf32',
    'auto_loss_weights',
    'auto_loss_weights_last_n',
    'balance_losses',
    'barrier_breaking',
    'beta3',
    'compile_unet',
    'inference_seed',
    'learning_rate_text',
    'logging_dir',
    'loss_clip',
    'lr_num_cycles',
    'merge_params',
    'mixed_precision',
    'mse_reduction',
    'num_validation_images',
    'output_dir',
    'param_exclude',
    'param_include',
    'patch_mlp',
    'pretrained_model_name_or_path',
    'pretrained_vae_model_name_or_path',
    'resolution',
    'scale_lr',
    'seed',
    'timestep_weights_file',
    'train_text_encoder',
];


export const OBSOLETE = [
    'accumulate_text',
    'adam_weight_decay_text',
    'cast_vae',
    'center_crop',
    'checkpoints_total_limit',
    'class_data_dir',
    'class_prompt',
    'crops_coords_top_left_h',
    'crops_coords_top_left_w',
    'dataloader_num_workers',
    'enable_xformers_memory_efficient_attention',
    'force_input_grad',
    'gradient_checkpointing',
    'hflip',
    'hub_model_id',
    'hub_token',
    'infer_original_size_from_caption',
    'local_rank',
    'num_class_images',
    'num_train_epochs',
    'pre_offset',
    'prior_generation_precision',
    'prior_loss_weight',
    'push_to_hub',
    'report_to',
    'resume_from_checkpoint',
    'revision',
    'sample_batch_size',
    'validation_original_size',
    'with_prior_preservation'
];


export const DATASET_KEYS = [
    'autocaption',
    'caption_prefix',
    'caption_suffix',
    'captions_file',
    'dropout_caption',
    'instance_data_dir',
    'instance_prompt',
    'negative_validation_prompt',
    'nsfw',
    'validation_prompt'
];


export const DEFAULT_ARGS = {
    abort: false,
    accumulate_text: false,
    adam_beta1: 0.9,
    adam_beta2: 0.99,
    adam_epsilon: 1e-8,
    adam_weight_decay: 0,
    adam_weight_decay_text: null,
    allow_tf32: true,
    alpha: 16,
    auto_loss_weights: true,
    auto_loss_weights_last_n: 1000,
    autocaption: false,
    balance_losses: false,
    barrier_breaking: false,
    beta3: 0.999499874937461,
    bucketing: true,
    caption_prefix: "pixel art: ",
    caption_suffix: " (with a grey background)",
    captions_file: "1024x1024/coa-bosses-again-south-gpt4.txt",
    cast_vae: false,
    center_crop: false,
    checkpointing_steps: 250,
    checkpoints_total_limit: null,
    class_data_dir: null,
    class_prompt: null,
    compel_no_truncate: false,
    compile_unet: false,
    compute_mean_std: false,
    crop_square: false,
    no_vae_cache: true,
    use_predefined_original_size: false,
    use_predefined_target_size: false,
    multi_aspect_training: true,
    batch_preprocessing: true,
    use_v2_transforms: false,
    classifier_zeroing: true,
    one_timestep_value: false,
    crop_coords_multiple: 8,
    prescale_into_bucket: true,
    zero_target_size: false,
    new_crop_calc: false,
    overwrite_original_size: true,
    crops_coords_top_left_h: 0,
    crops_coords_top_left_w: 0,
    dataloader_num_workers: 0,
    dataset_mean: "[0.5]",
    dataset_std: "[0.5]",
    denoiser_steps: 20,
    dropout_caption: "",
    dropout_set_zero: true,
    // dropout_set_time_ids_zero: true,
    enable_xformers_memory_efficient_attention: false,
    force_input_grad: false,
    freeze_text_encoder_after: null,
    gradient_accumulation_steps: 1,
    gradient_checkpointing: false,
    guidance_scale: 7.5,
    hflip: 0,
    hub_model_id: null,
    hub_token: null,
    infer_original_size_from_caption: false,
    inference_height: 1024,
    inference_scheduler: "eulera",
    inference_seed: 979242106,
    inference_width: 1024,
    instance_data_dir: "1024x1024",
    instance_prompt: "pixel art: A sprite asset showing a friendly blacksmith facing south.|pixel art: a game level with a beautiful lush forest and a bandit encampment",
    learning_rate: 0.000125,
    learning_rate_text: 0.0005,
    local_rank: -1,
    logging_dir: "logs",
    lora_dropout: 0.0,
    lora_init: true,
    lora_fan_in_out: false,
    lora_bias: "none", // "none", "all", "lora_only"
    lora_module_regex: "FINETUNE",
    loss_clip: 200000,
    loss_mask_blur_strength: 0,
    loss_mask_empty_weight: 0,
    loss_mask_from_white: false,
    loss_weighting_file: null,
    lr_multiplier: 1,
    lr_num_cycles: 1,
    lr_power: 1,
    lr_scheduler: "constant",
    lr_warmup_discard: false,
    lr_warmup_steps: 1000,
    max_grad_norm: 0,
    max_train_steps: 10000,
    merge_params: false,
    mixed_precision: "no",
    mse_reduction: "mean",
    negative_validation_prompt: "ugly",
    no_lora: false,
    noise_offset: 0.05,
    noise_offset_exclude_dropout: false,
    nonlinear_cutoff: 0,
    nonlinear_inverse: false,
    nonlinear_timesteps: false,
    nsfw: false,
    num_class_images: 100,
    num_train_epochs: 35,
    num_validation_images: 8,
    output_dir: "projects/pixels-randomized-coa2/24-07-19/11h33m04s",
    p_caption_dropout: 0.0,
    p_default_timestepping: 0.2,
    p_preserve: 0.25,
    preserve_count: 2,
    preservation_lr_multiplier: 1,
    param_exclude: null,
    param_include: null,
    patch_mlp: false,
    pre_offset: false,
    precompute_embeddings: true,
    precompute_latents: false,
    pretrained_model_name_or_path: "stabilityai/stable-diffusion-xl-base-1.0",
    pretrained_vae_model_name_or_path: null,
    prior_generation_precision: null,
    prior_loss_weight: 1,
    prodigy: false,
    prodigy_bias_correction: false,
    prodigy_d0: 0.000001,
    prodigy_d_coef: 1,
    prodigy_max_growth_rate: null,
    prodigy_safeguard_warmup: true,
    push_to_hub: false,
    rank: 16,
    report_to: "tensorboard",
    resolution: 1024,
    resume_from_checkpoint: null,
    revision: null,
    sample_batch_size: 4,
    scale_lr: false,
    seed: 69069799,
    single_timestep_range: null,
    single_timestep_value: null,
    text_rank: 128,
    timestep_weights_file: null,
    train_batch_size: 4,
    train_text_encoder: false,
    unet_as_eval: false,
    use_8bit_adam: false,
    use_adagrad: false,
    use_compel: true,
    use_rslora: false,
    use_sgd: false,
    vae_no_sample: false,
    validation_epochs: 250,
    validation_original_size: null,
    validation_prompt: "pixel art: A sprite asset showing a friendly blacksmith facing south.|pixel art: a game level with a beautiful lush forest and a bandit encampment",
    with_prior_preservation: false
};

export const FILTERED_ARGS = Object.fromEntries(Object.entries(DEFAULT_ARGS).filter(([k, v]) => !OBSOLETE.includes(k) && !HIDDEN.includes(k) && !DATASET_KEYS.includes(k)));
export const OBSOLETE_ARGS = Object.fromEntries(Object.entries(DEFAULT_ARGS).filter(([k, v]) => OBSOLETE.includes(k)));
export const HIDDEN_ARGS = Object.fromEntries(Object.entries(DEFAULT_ARGS).filter(([k, v]) => HIDDEN.includes(k)));
export const DATASET_ARGS = Object.fromEntries(Object.entries(DEFAULT_ARGS).filter(([k, v]) => DATASET_KEYS.includes(k)));

const DATA_TYPES = ['int', 'float', 'str', 'bool', 'list', 'dict', 'tuple', 'set', 'None'];

// Mapping of key to value, e.g. "abort": "bool"

export const ARG_TYPE_MAP = {
    // "abort": "bool",
    "accumulate_text": "bool",
    "adam_beta1": "float",
    "adam_beta2": "float",
    "adam_epsilon": "float",
    "adam_weight_decay": "float",
    "adam_weight_decay_text": "float",
    "allow_tf32": "bool",
    "alpha": "int",
    "auto_loss_weights": "bool",
    "auto_loss_weights_last_n": "int",
    "autocaption": "bool",
    "balance_losses": "bool",
    "barrier_breaking": "bool",
    "beta3": "float",
    "caption_prefix": "str",
    "caption_suffix": "str",
    "captions_file": "str",
    "cast_vae": "bool",
    "center_crop": "bool",
    "checkpointing_steps": "int",
    "checkpoints_total_limit": "int",
    // "class_data_dir": "str",
    // "class_prompt": "str",
    "compile_unet": "bool",
    "compute_mean_std": "bool",
    // "crops_coords_top_left_h": "int",
    // "crops_coords_top_left_w": "int",
    // "dataloader_num_workers": "int",
    "dataset_mean": "list",
    "dataset_std": "list",
    "denoiser_steps": "int",
    "dropout_caption": "str",
    "dropout_set_time_ids_zero": "bool",
    "dropout_set_zero": "bool",
    // "enable_xformers_memory_efficient_attention": "bool",
    "force_input_grad": "bool",
    "freeze_text_encoder_after": "int",
    "gradient_accumulation_steps": "int",
    "gradient_checkpointing": "bool",
    "guidance_scale": "float",
    "hflip": "float",
    // "hub_model_id": "str",
    // "hub_token": "str",
    "infer_original_size_from_caption": "bool",
    "inference_height": "int",
    "inference_scheduler": "str",
    "inference_seed": "int",
    "inference_width": "int",
    "instance_data_dir": "str",
    "instance_prompt": "str",
    "learning_rate": "float",
    "learning_rate_text": "float",
    "local_rank": "int",
    // "logging_dir": "str",
    "lora_module_regex": "str",
    "loss_clip": "float",
    "loss_mask_blur_strength": "float",
    "loss_mask_empty_weight": "float",
    "loss_mask_from_white": "bool",
    "loss_weighting_file": "str",
    "lr_multiplier": "float",
    "lr_num_cycles": "int",
    "lr_power": "float",
    "lr_scheduler": "str",
    "lr_warmup_discard": "bool",
    "lr_warmup_steps": "int",
    "max_grad_norm": "int",
    "max_train_steps": "int",
    "merge_params": "bool",
    // "mixed_precision": "str",
    "mse_reduction": "str",
    "negative_validation_prompt": "str",
    "no_lora": "bool",
    "noise_offset": "float",
    "nonlinear_cutoff": "int",
    "nonlinear_inverse": "bool",
    "nonlinear_timesteps": "bool",
    "nsfw": "bool",
    // "num_class_images": "int",
    // "num_train_epochs": "int",
    "num_validation_images": "int",
    // "output_dir": "str",
    "p_caption_dropout": "float",
    "p_default_timestepping": "float",
    "param_exclude": "str",
    "param_include": "str",
    "patch_mlp": "bool",
    "pre_offset": "bool",
    "precompute_embeddings": "bool",
    "precompute_latents": "bool",
    "pretrained_model_name_or_path": "str",
    "pretrained_vae_model_name_or_path": "str",
    // "prior_generation_precision": "str",
    // "prior_loss_weight": "float",
    "prodigy": "bool",
    "prodigy_bias_correction": "bool",
    "prodigy_d0": "float",
    "prodigy_d_coef": "int",
    "prodigy_max_growth_rate": "int",
    "prodigy_safeguard_warmup": "bool",
    // "push_to_hub": "bool",
    "rank": "int",
    // "report_to": "str",
    // "resolution": "int",
    // "resume_from_checkpoint": "str",
    // "revision": "str",
    "sample_batch_size": "int",
    "scale_lr": "bool",
    "seed": "int",
    "single_timestep_range": "str",
    "single_timestep_value": "str",
    "text_rank": "int",
    "timestep_weights_file": "str",
    "train_batch_size": "int",
    "train_text_encoder": "bool",
    "unet_as_eval": "bool",
    "use_8bit_adam": "bool",
    "use_adagrad": "bool",
    "use_compel": "bool",
    "use_rslora": "bool",
    "use_sgd": "bool",
    "vae_no_sample": "bool",
    "validation_epochs": "int",
    "validation_original_size": "str",
    "validation_prompt": "str",
    // "with_prior_preservation": "bool"
}

export const DEFAULT_CODE = [
    {
        filename: "train-lora.py",
        url: "https://gist.githubusercontent.com/aristotaloss/afaaf43dfefde86344ab482df6ab279d/raw"
    },
    {
        filename: "monitoring.py",
        url: "https://gist.github.com/aristotaloss/d3e05c6e05ddadb5bfeedcb74bb37421/raw"
    },
    {
        filename: "decelerate.py",
        url: "https://gist.githubusercontent.com/aristotaloss/19e590b0868f928594b2b828fdf15ff2/raw"
    },
    {
        filename: "loss_weight.py",
        url: "https://gist.github.com/aristotaloss/a3b4ea87aefc6c79af3de72910117fda/raw"
    },
    {
        filename: "loss-weights.json",
        url: "https://gist.github.com/aristotaloss/a009fe86c1988f80eca8fa7ed9660ffa/raw"
    }
];