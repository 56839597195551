import axios from "axios";

export let nsfw = false;

export function setNsfw(value) {
    nsfw = value;
}

export async function search(data) {
    try {
        const response = await axios.post("https://ctlzr-api.bitgate.workers.dev/artemis/mlruns/_search", data);
        return response;
    } catch (e) {
        console.error(e);

        // Read error response
        if (e.response) {
            console.error(e.response.data);
        }

        return null;
    }
}

export async function fetchResults(maxResults, filters = {}) {
    const filterArray = Object.keys(filters).filter(k => filters[k] !== null && filters[k] !== undefined).map(key => ({
        match: {
            [`image_results.args.${key}`]: filters[key]
        }
    }));

    const must_not = [
        { query_string: { query: "image_results.all_images:loss" } },
    ];

    if (!nsfw) {
        must_not.push({ term: { "image_results.args.nsfw": true } })
    }

    const query = {
        size: maxResults * 2,
        query: {
            bool: {
                must: [
                    { term: { event: "image_results" } },
                    ...filterArray
                ],
                must_not
            }
        },
        sort: [{ "@timestamp": { order: "desc" } }]
    };

    const response = await search(query);

    const values = response.data.hits.hits.map(hit => ({
        ...hit._source.image_results,
        run_id: hit._source.run_id,
        "@timestamp": hit._source["@timestamp"],
        all_images: []
    }));

    return values;
}

export async function countImagesForModel(model) {
    const query = {
        size: 0,
        query: {
            bool: {
                must: [
                    // { match: { event: "inference" } },
                    { term: { "inference.model.keyword": model } }
                ]
            }
        }
    };

    const response = await search(query);

    return response.data.hits.total.value;
}
window.countImagesForModel = countImagesForModel;

export async function countImagesPerModel() {
    const query = {
        size: 0,
        aggs: {
            models: {
                terms: {
                    field: "inference.model.keyword",
                    size: 1000
                }
            }
        }
    };

    const response = await search(query);

    return Object.fromEntries(response.data.aggregations.models.buckets.map(b => [b.key, b.doc_count]));
}

window.countImagesPerModel = countImagesPerModel;

export async function fetchImageHistory(maxResults, filters = {}, queryString = null, paginationOffset = 0) {
    const filterArray = Object.keys(filters)
        .filter(k => filters[k] !== null && filters[k] !== undefined)
        .map(key => ({
            term: { [`inference.${key}`]: filters[key] }
        }));

    let must = [
        { term: { "event": "inference" } },
        ...filterArray
    ];

    if (queryString && queryString.length) {
        must.push({ query_string: { query: queryString } });
    }

    let must_not = Object.keys(filters)
        .filter(k => filters[k] === null && filters[k] !== undefined)
        .map(key => ({
            term: { [`inference.${key}`]: true }
        }));

    if (!nsfw) {
        must_not.push({ term: { "inference.model.keyword": "ld" } });
        must_not.push({ match: { "inference.prompt": "woman OR girl OR naked OR ass OR nsfw OR erotic OR pussy" } });
    }

    const query = {
        size: maxResults,
        from: paginationOffset ?? 0,
        query: {
            bool: { must, must_not }
        },
        sort: [{ "inference.key.keyword": { order: "desc" } }],
    };

    const response = await search(query);
    const values = response.data.hits.hits.map(hit => ({
        ...hit._source
    }));

    const total = response.data.hits.total.value;

    return { values, total };
}


export async function fetchStatus() {
    const query = {
        size: 1,
        query: {
            bool: {
                must: [
                    { term: { "event": "current_status" } },
                ]
            }
        },
        sort: [
            { "@timestamp": { order: "desc" } }
        ]
    };


    const response = await search(query);

    const values = response.data.hits.hits.map(e => e._source);

    return values;
}

export async function getLoraPreviews(list) {
    const queryValues = list.map(m => {
        const path = m.path;
        const step = Number(path.substring(path.lastIndexOf('/') + 1).replace('checkpoint-', ''));

        return { output_dir: m.args.output_dir, step };
    });

    const query = {
        size: list.length,
        query: {
            bool: {
                should: queryValues.map(lora => ({
                    bool: {
                        must: [
                            {
                                term: {
                                    "image_results.args.output_dir.keyword": lora.output_dir
                                }
                            },
                            {
                                term: {
                                    "image_results.step": lora.step
                                }
                            }
                        ],
                    }
                })),
            }
        },
        fields: [
            "image_results.images", "image_results.args.output_dir", "run_id"
        ],
        _source: false
    };

    const response = await search(query);
    const values = response.data.hits.hits.map(e => e.fields);

    let lookup = {};
    for (const entry of values) {
        const output_dir = entry["image_results.args.output_dir"];
        const images = entry["image_results.images"];
        const run_id = entry["run_id"];

        lookup[output_dir] = {
            output_dir, images, run_id
        }
    }

    return lookup;
}


export async function getLoraPreview(timestamp, step) {
    const query = {
        size: 1,
        query: {
            bool: {
                must: [
                    {
                        match: {
                            "event": "image_results",
                            "image_results.args.output_dir": `projects/pixels-randomized-coa2/${timestamp}`,
                            "image_results.step": step + ""
                        }
                    }
                ]
            }
        },
        // sort: [
        //     { "@timestamp": { order: "desc" } }
        // ]
    };


    const response = await search(query);

    const values = response.data.hits.hits.map(e => e._source);

    return values;
}

export async function fetchRunArgs(runId) {
    const response = await axios.get("https://ctlzr-api.bitgate.workers.dev/artemis/mlruns/_doc/" + runId + ".run_args");

    const values = response.data._source;

    return values ? values.run_args : {};
}

export async function findRunArgs(outputFolder, step) {
    const query = {
        size: 1,
        query: {
            bool: {
                must: [
                    { match: { event: "current_status" } },
                    { match: { "current_status.args.output_dir": outputFolder } },
                    { match: { "current_status.step": step } }
                ]
            }
        },
    };

    const response = await search(query);

    const values = response.data.hits.hits.map(e => e._source);

    return values.length > 0 ? values[0] : null;
}

export async function getWasabiFile(key) {
    const response = await axios.get("https://ctlzr-api.bitgate.workers.dev/api/v1/wasabi?key=" + key);

    return response.data;
}

export async function putRunArgs(runId, args) {
    const response = await axios.put("https://ctlzr-api.bitgate.workers.dev/artemis/mlruns/_doc/" + runId + ".run_args", { run_args: args });
    return response;
}


export async function logArgumentModified(runId, oldArgs, newArgs) {
    const modifiedKeys = [];

    const all = new Set([...Object.keys(oldArgs), ...Object.keys(newArgs)]);
    for (const key of all) {
        if (!oldArgs.hasOwnProperty(key) || !newArgs.hasOwnProperty(key) || oldArgs[key] !== newArgs[key]) {
            modifiedKeys.push(key);
        }
    }

    const obj = {
        "@timestamp": new Date().toISOString(),
        "run_id": runId,
        "event": "modify_args",
        "modify_args": {
            "old": oldArgs,
            "new": newArgs,
            "modified_keys": modifiedKeys,
        },
    };

    return await axios.post("https://ctlzr-api.bitgate.workers.dev/artemis/mlruns/_doc/", obj);
}

export async function listModels() {
    const resp = await fetch("https://training-api.bitgate.ai/models");
    const json = await resp.json();

    return json;
}

export async function listVaeModels() {
    const resp = await fetch("https://training-api.bitgate.ai/vae-models");
    const json = await resp.json();

    return json;
}

export async function listRemoteModels() {
    const resp = await fetch("https://ctlzr-api.bitgate.workers.dev/api/v1/list-saved-models");
    const json = await resp.json();

    return json.models;
}

export async function invokeLLM(system, user, temperature = 1, image) {
    const resp = await fetch("https://ctlzr-api.bitgate.workers.dev/api/v1/llm", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ system, user, temperature, image })
    });

    const json = await resp.json();
    return json;
}

export async function downloadModel(modelPath) {
    const body = JSON.stringify({ model: modelPath });
    const resp = await fetch("https://training-api.bitgate.ai/download-model", { method: "POST", body });

    return await resp.json();
}

export async function deleteModel(modelPath) {
    const body = JSON.stringify({ model: modelPath });
    await fetch("https://training-api.bitgate.ai/delete-model", { method: "POST", body });
}

export async function queueImageGeneration(args) {
    await fetch("https://ctlzr-api.bitgate.workers.dev/api/v1/jobs/inference?h=" + Math.random(), {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(args)
    });
}

export async function getQueuedTrainingTasks() {
    const resp = await fetch("https://ctlzr-api.bitgate.workers.dev/api/v1/jobs/sdxl-training?h=" + Math.random());
    const json = await resp.json();

    return json;
}

export async function deleteAllQueuedTrainingTasks() {
    await fetch("https://ctlzr-api.bitgate.workers.dev/api/v1/jobs/sdxl-training/all", { method: "DELETE" });
}

export async function queueTraining(args) {
    await fetch("https://ctlzr-api.bitgate.workers.dev/api/v1/jobs/sdxl-training?h=" + Math.random(), {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(args)
    });
}

export async function setTrainingPriority(taskId, priority) {
    await fetch("https://ctlzr-api.bitgate.workers.dev/api/v1/jobs/sdxl-training/" + taskId + "/priority/" + priority, {
        method: "POST"
    });
}

export async function deleteTrainingTask(taskId) {
    await fetch("https://ctlzr-api.bitgate.workers.dev/api/v1/jobs/sdxl-training/" + taskId, { method: "DELETE" });
}

export async function getEvalTasks() {
    const resp = await fetch("https://ctlzr-api.bitgate.workers.dev/api/v1/jobs/eval?h=" + Math.random());
    return await resp.json();
}